import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout';
import Hero from 'components/Layout/Hero';
import ArticleCard from 'components/ArticleCard';
import Search from 'components/Search';
import * as s from './articlesRoot.module.css';

const searchIndices = [{ name: `Blog`, title: `Blog` }];

const POPULAR_SEARCH = [
  'ASIC',
  'Ethereum',
  'Bitcoin',
  'AMD',
  'NVIDIA',
  'GPU',
  'Security',
  'ASIC Hub',
  'Start mining',
];

const ArticlesRoot = props => {
  const data = _get(props, 'pageContext.data', []);
  const currentLang = _get(props, 'pageContext.currentLang', 'en');
  const isDataEmpty = _get(data, 'length') === 0;
  const [isSearching, setSearching] = useState(false);

  return (
    <Layout extraTitle="Blog" lang={currentLang} page="blog" registrationURL={_get(props, 'pageContext.registrationURL', '')}>
      <Hero headerIntl={<FormattedMessage id="blog.newsArticlesList" defaultMessage="News and Articles" />} />
      {isDataEmpty &&
        <div className={s.noArticles}>
          <FormattedMessage id="blog.noArticles" defaultMessage="There are no articles yet" />
        </div>
      }
      <div className={s.search}>
        <Search
          indices={searchIndices}
          setSearching={setSearching}
          isSearching={isSearching}
          popular={POPULAR_SEARCH}
        />
      </div>
      {!isSearching && !isDataEmpty && data.map((item, index) => (
          <ArticleCard title={item.title} img={item.previewImg} link={item.path} key={`${index}_article`} date={item.date} />
        )
      )}
    </Layout>
  )
};

export default ArticlesRoot;
