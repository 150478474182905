import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import { ARTICLE_PREVIEW_IMG_NONE } from 'constants/blog';
import * as styles from './articleCard.module.css';

const ArticleCard = ({ img, title, link, date }) => {
  const formatedDate = moment(date).format('DD MMM YYYY');

  return (
    <div className={styles.wrapper}>
      <div itemScope itemType="https://schema.org/NewsArticle" className={styles.root}>
        {img && img !== ARTICLE_PREVIEW_IMG_NONE && <img itemProp="image" src={img} alt="article-preview" />}
        <div className={styles.row}>
          <span itemProp="articleSection" className={styles.category}><FormattedMessage id="blog.news" /></span>
          <div className={styles.dot}/>
          <span itemProp="datePublished" className={styles.category}>{formatedDate}</span>
        </div>
        <Link itemProp="headline" className={styles.title} to={link}>
          <h2 className={styles.title}>{title}</h2>
        </Link>
        <Link className={styles.articleLink} to={link}>
          <FormattedMessage id="blog.readMore" />
        </Link>
      </div>
    </div>
  )
};

ArticleCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default ArticleCard;
